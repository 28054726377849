import axiosInstance from "../axios-instance";
import * as CONSTANTS from "../constants";

const getApps = ()=> {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/revamp/landing-page/widgets`
    });
};

const getBusinessTypes = ()=> {
    return axiosInstance({
        method: "get",
        url: `${CONSTANTS.REACT_APP_BACKEND_URL}/revamp/landing-page/all-business-types`
    });
};

const postJoinPartnerProgram = (data: any) => {
    return axiosInstance({
      method: `post`,
      url: `${CONSTANTS.MP_OLD_PORTAL_URL}/api/partnerprogramme`,
      data: data
    })
}

const getDistricts = async () => {
    return await axiosInstance({
      method: `get`,
      url: `${CONSTANTS.MP_OLD_PORTAL_URL}/api/districts/getDistrictsExt`
    })
}

const getMaList = async (search_term: any, offset: any, limit: any) => {
    return await axiosInstance({
      method: `get`,
      url: `${CONSTANTS.MP_OLD_PORTAL_URL}/api/ent/marketingagent/${search_term}/${offset}/${limit}`
    })
  }
  
  const getMaDetails = async (uuid: any) => {
    return await axiosInstance({
      method: `get`,
      url: `${CONSTANTS.MP_OLD_PORTAL_URL}/api/ent/marketingagent/${uuid}`
    })
  }

export {
    getApps,
    getBusinessTypes,
    postJoinPartnerProgram,
    getDistricts,
    getMaList,
    getMaDetails
};