import React, { useEffect, useState } from 'react';
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import RouteHandler from './routes/RouteHandler';
import { Spin } from 'antd';
import { Spinner } from './components/Spinner';

const antIcon = <Spinner />;

function App() {
  const paths = window.location.pathname.split('/');



  return (
    <React.Fragment>
      <Router>
        {/* <Spin size="large" spinning={!keycloak && paths[1] !== 'signup' && paths[1] !== 'agreement' && (paths[1] !== "apps")} indicator={antIcon}> */}
        <RouteHandler />
        {/* </Spin> */}
      </Router>
    </React.Fragment>
  );
}

export default App;