import React, { useEffect, useState } from 'react';
import Pack1 from '../../assets/images/branding/tips/pack-1.svg';
import Pack2 from '../../assets/images/branding/tips/pack-2.svg';
import Pack3 from '../../assets/images/branding/tips/pack-3.svg';
import Pack4 from '../../assets/images/branding/tips/pack-4.svg';
import Sol1 from '../../assets/images/icons/sol-1.webp';
import Sol2 from '../../assets/images/icons/sol-2.webp';
import Sol3 from '../../assets/images/icons/sol-3.webp';
import Sol4 from '../../assets/images/icons/sol-4.webp';
import Partner1 from '../../assets/images/Partner3New.png';
import PlaceholderPartner from '../../assets/images/Partner2New.png';
import Partner2 from '../../assets/images/PartnerNew1.png';
import Partner3 from '../../assets/images/Partner4New.png';
import letsTalk from '../../assets/images/cheerful-co-workers-office-during-company-meeting.jpg'
import Product1FeatureBg from '../../assets/images/product-1-feature-bg-img.webp';
import Feature1 from '../../assets/images/feature-1.png';
import Feature2 from '../../assets/images/feature-2.png';
import Feature3 from '../../assets/images/feature-3.png';
import IdeamartLogo from '../../assets/images/branding/primary-ideamart.svg';
import DialogLogo from '../../assets/images/branding/tiny-Dialog_logo.svg';
import myCallCenterImg from '../../assets/images/my-call-center.png';
import betterHRImg from '../../assets/images/better-hr.png';
import betterHRLogo from '../../assets/images/betterHrLogo.svg';
import videoModule from '../../assets/videos/Final_Demo.mp4';
//import videoModule from '../../assets/images/final-demo.mp4';
import DELogo from '../../assets/images/DE Logo - SVG.svg'

import { url } from 'inspector';
import * as Constants from '../../constants';

function App() {
    const paths = window.location.pathname.split('/');

    const [video, setVideo] = useState(videoModule)

    const onClickExplore = () => {
        window.open(`${Constants.PORTAL_URL}/apps/wizard`, "_blank") || window.location.replace(`${Constants.PORTAL_URL}/apps/wizard`)
    }

    const onClickSignUp = () => {
        window.open(`${Constants.PORTAL_URL}/signup`, "_blank") || window.location.replace(`${Constants.PORTAL_URL}/signup`)
    }
    return (
        <React.Fragment>
            {/*/top nav*/}
            {/* top hero section */}
            <section className="main-hero primary">
                <div className="container">
                    <div className="main-content__wrapper">
                        <div className="primary-content__wrapper">
                            <div className="header-text">
                                <h1>Continuously Improve Your</h1>
                                <div className="header-text-slider__wrapper">
                                    <div className="text-slider text-slider1">
                                        <h1>Customer Experience</h1>
                                    </div>
                                    <div className="text-slider text-slider2">
                                        <h1>Earnings</h1>
                                    </div>
                                    <div className="text-slider text-slider3">
                                        <h1>Sales</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="title-text">
                                <h4>
                                    Offers innovative digital solutions at an affordable cost to empower small & medium businesses <br />{" "}
                                    to digitally transform their organizations, grow their revenue and sustain their businesses!
                                </h4>
                            </div>
                            <div className="btn-group__wrapper">
                                <button className="btn btn-primary btn-lg round" onClick={onClickExplore}>Try Now</button>
                                <button className="btn btn-secondary btn-lg round" onClick={onClickSignUp}>Sign Up</button>
                            </div>
                        </div>
                        <div className="secondary-content__wrapper">
                            <h5>Powered by</h5>
                            <div className="partner-intro__wrapper">
                                {/* <img
                                    src={IdeamartLogo}
                                    alt=""
                                    srcSet=""
                                /> */}
                                <img
                                    src={DELogo}
                                    alt=""
                                    srcSet=""
                                />
                                {/* <img src="/public/assets/images/D" alt="" srcset=""> */}
                            </div>
                            <div className=" main-hero-video-wrapper">
                                <video autoPlay muted loop>
                                    <source src={video} type="video/mp4" />
                                    <p>Your browser does not support the video tag.</p>
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* / top hero section */}
            {/* Quick tips section */}
            <section className="quick-tips">
                <div className="cotainer-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="quick-tips__wrapper">
                                {/* item */}
                                <div className="tip-item">
                                    <div
                                        className="img__wrapper"
                                        style={{
                                            backgroundImage: `url(${Pack1})`
                                        }}
                                    />
                                    <div className="text__wrapper">
                                        <p className="tip-title">Easy Sign Up &amp; Configuration</p>
                                        <p className="tip-des">
                                            Interact with your customers faster &amp; easier.
                                        </p>
                                    </div>
                                </div>
                                {/* /item */}
                                {/* item */}
                                <div className="tip-item">
                                    <div
                                        className="img__wrapper"
                                        style={{
                                            backgroundImage: `url(${Pack2})`
                                        }}
                                    />
                                    <div className="text__wrapper">
                                        <p className="tip-title">Pay As You Go</p>
                                        <p className="tip-des">
                                            No hidden fees, no upfront fees, just pay as you go.
                                        </p>
                                    </div>
                                </div>
                                {/* /item */}
                                {/* item */}
                                <div className="tip-item">
                                    <div
                                        className="img__wrapper"
                                        style={{
                                            backgroundImage: `url(${Pack3})`
                                        }}
                                    />
                                    <div className="text__wrapper">
                                        <p className="tip-title">One Stop Shop</p>
                                        <p className="tip-des">
                                            Single platform with multiple solutions for all business
                                            needs.
                                        </p>
                                    </div>
                                </div>
                                {/* /item */}
                                {/* item */}
                                <div className="tip-item">
                                    <div
                                        className="img__wrapper"
                                        style={{
                                            backgroundImage: `url(${Pack4})`
                                        }}
                                    />
                                    <div className="text__wrapper">
                                        <p className="tip-title">Support</p>
                                        <p className="tip-des">
                                            Contact our Marketplace team for your any supports request.
                                        </p>
                                    </div>
                                </div>
                                {/* /item */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* / Quick tips section */}
            {/* clients slider */}
            <section className="items-slider clients-list common-section center-content ">
                <div className="center-details__wrapper">
                    <p className="sec-sub-title">Our Portfolio </p>
                    <h2 className="sec-main-title">
                        Trusted by Leading 6000+ Companies to Drive Growth
                    </h2>
                </div>
                <div className="slider-listof-items">
                    <img src={require('../../assets/images/Honda-StaffordNew.svg').default} alt="Image 1" />
                    <img src={require('../../assets/images/Amrak-LogoNew.svg').default} alt="Image 2" />
                    <img src={require('../../assets/images/AminraNew.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/kangarooNew.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/Mimosa-UnionNew.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/oncreditNew.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/Abans Logo.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/Carmart logo (003).svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/Currybox Helment Logo-1.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/DENTAL LOGO-1.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/Eric logo2021-1.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/MAINLogo-HD_H_21.01.05.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/SDF English.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/SpexBay Logo-1.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/CGJTA-1.svg').default} alt="Image 3" />
                    <img src={require('../../assets/images/ML logo8-7-21-1.svg').default} alt="Image 3" />
                </div>
            </section>
            {/*/ cliennts slider*/}
            {/* What is */}
            <section
                className="what-is common-section center-content bg-line-pattern"
                id=""
            >
                <div className="container-fluid">
                    <div className="container">
                        <div className="center-details__wrapper">
                            <p className="sec-sub-title">What is Marketplace?</p>
                            <h2 className="sec-main-title">
                                One Stop Shop for All Your Business Needs!
                            </h2>
                            <p className="sec-dec-wrapper">
                            A centralized platform provides self-service access to tailored mobility and digital tools for businesses to address their unique challenges. With over 100+ digital solutions available, the majority operate on a cost-effective pay-as-you-use model to eliminate high up-front investments in digital tools.
                            </p>
                        </div>
                        {/* <div className="stats-details__wrapper row"> */}
                            {/* item */}
                            {/* <div className="stats-item col-md-4">
                                <h1 className="stats-title">100+</h1>
                                <p className="stats-dec">
                                    Digital Solutions
                                </p>
                            </div> */}
                            {/* /item */}
                            {/* item */}
                            {/* <div className="stats-item col-md-4">
                                <h1 className="stats-title">6,000+</h1>
                                <p className="stats-dec">Enterprise Customers</p>
                            </div> */}
                            {/* /item */}
                            {/* item */}
                            {/* <div className="stats-item col-md-4">
                                <h1 className="stats-title">15+</h1>
                                <p className="stats-dec">
                                    Solution Partners
                                </p>
                            </div> */}
                            {/* /item */}
                        {/* </div> */}
                    </div>
                </div>
            </section>
            {/* / What is */}
            {/* Why marketplace */}
            <section
                className="why-marketplace common-section left-content bg-circle-pattern"
                id=""
            >
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="left-details__wrapper">
                                    <p className="sec-sub-title">Why Marketplace?</p>
                                    <h2 className="sec-main-title">
                                        Take Care of Everything of Your Business with Marketplace
                                    </h2>
                                    <p className="sec-dec-wrapper">
                                        We keep adding new features to our platform with keep in mind of
                                        our clients requirements
                                    </p>
                                    <button className="btn btn-inline link-blue" onClick={onClickExplore}>
                                        Explore more solutions <span />
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="two-col">
                                    {/* item */}
                                    <li className="one-col">
                                        <div
                                            className="image__wrapper"
                                            style={{
                                                backgroundImage: `url(${Sol1})`
                                            }}
                                        />
                                        <div className="text__wrapper">
                                            <h5>Promote Your Business</h5>
                                            <ul>
                                                <li>
                                                    <i className="bi bi-check2-circle" /> Smart Reach
                                                </li>
                                                <li>
                                                    <i className="bi bi-check2-circle" /> Targeted Campaigns
                                                </li>
                                                <li>
                                                    <button className="btn btn-inline link-blue" onClick={onClickExplore}>
                                                        Learn more <span />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* /item */}
                                    {/* item */}
                                    <li className="one-col">
                                        <div
                                            className="image__wrapper"
                                            style={{
                                                backgroundImage: `url(${Sol2})`
                                            }}
                                        />
                                        <div className="text__wrapper">
                                            <h5>Affordable HR Solutions</h5>
                                            <ul>
                                                <li>
                                                    <i className="bi bi-check2-circle" /> Better HR
                                                </li>
                                                <li>
                                                    <i className="bi bi-check2-circle" /> Exployee attendance
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* /item */}
                                    {/* item */}
                                    <li className="one-col">
                                        <div
                                            className="image__wrapper"
                                            style={{
                                                backgroundImage: `url(${Sol3})`
                                            }}
                                        />
                                        <div className="text__wrapper">
                                            <h5>Cloud & Data Center</h5>
                                            <ul>
                                                <li>
                                                    <i className="bi bi-check2-circle" /> GWS
                                                </li>
                                                <li>
                                                    <i className="bi bi-check2-circle" /> Dialog Biz.lk
                                                </li>
                                                <li>
                                                    <button className="btn btn-inline link-blue" onClick={onClickExplore}>
                                                        Learn more <span />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* /item */}
                                    {/* item */}
                                    <li className="one-col">
                                        <div
                                            className="image__wrapper"
                                            style={{
                                                backgroundImage: `url(${Sol4})`
                                            }}
                                        />
                                        <div className="text__wrapper">
                                            <h5>Employee &amp; Customer Engagement</h5>
                                            <ul>
                                                <li>
                                                    <i className="bi bi-check2-circle" /> Customer
                                                    Satisfaction Feedback Analysis
                                                </li>
                                                <li>
                                                    <i className="bi bi-check2-circle" /> Surveys and Voting
                                                    Solutions
                                                </li>
                                                <li>
                                                    <button className="btn btn-inline link-blue" onClick={onClickExplore}>
                                                        Learn more <span />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* /item */}
                                    {/* item */}
                                    {/* <li class="one-col">
                          <div class="image__wrapper"
                              style="background-image: url(/public/assets/images/icons/pie-chart.svg);"></div>
                          <div class="text__wrapper">
                              <h5>Long solution type title goes here</h5>
                              <ul>
                                  <li><i class="bi bi-check2-circle"></i> feature one</li>
                                  <li><i class="bi bi-check2-circle"></i> feature two</li>
                                  <li><button class="btn btn-inline link-blue">Lot more <span></span></button>
                                  </li>
                              </ul>
                          </div>
                      </li> */}
                                    {/* /item */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* / Why martketplace */}
            <section
                className=" common-section center-content bg-primary bg-decorative-circle "
                id=""
            >
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="center-details__wrapper">
                                <p className="sec-sub-title txt-white">WHAT DO OUR CUSTOMERS SAY ABOUT US</p>
                                <h2 className="sec-main-title txt-white">
                                    "Proud partner for a year, connecting businesses with precision. Join me in elevating success
                                    with innovation and reliability on our transformative journey." {" "}
                                </h2>
                                <div className="sp-personal-details__wrapper">
                                    <div
                                        className="personal-avatar-holder"
                                        style={{
                                            backgroundImage: `url(${Partner1})`
                                        }}
                                    />
                                    <p className="personal-details__Wrapper txt-white">
                                        Viraj Hewage&nbsp;
                                        <svg
                                            width="7.5px"
                                            height="20px"
                                            viewBox="0 0 7.5 20"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <g
                                                id="Desktop"
                                                stroke="none"
                                                strokeWidth={1}
                                                fill="none"
                                                fillRule="evenodd"
                                            >
                                                <g
                                                    id="Web-Assets"
                                                    transform="translate(-566.500000, -76.000000)"
                                                    fill="#FDC124"
                                                >
                                                    <polygon
                                                        id="Path-2"
                                                        points="566.5 96 569 96 574 76 571.5 76"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                        &nbsp; <span>Digital Lead – Abans PLC</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Why marketplace */}
            <section
                className="brief-partner common-section center-content"
                id=""
            >
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="center-details__wrapper">
                                <p className="sec-sub-title">CONSULT OUR MARKETPLACE PARTNERS FOR TAILORED GUIDANCE</p>
                                <h2 className="sec-main-title">
                                    Navigate with Confidence: Consult Our Marketplace Partners for Tailored Guidance
                                </h2>
                                <p className="sec-dec-wrapper">
                                    Join Dialog Marketplace as an influential partner, qualified individuals and companies serving as front-end ambassadors. Unlock benefits, be integral to our collaborative success story.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <div
                                    className="placeholder-partner"
                                    style={{
                                        backgroundImage: `url(${PlaceholderPartner})`
                                    }}
                                />
                            </div>
                            <div className="col-md-7">
                                <div className="partner-spread__wrapper">
                                    <div className="partner-spread-left">
                                        {/* item */}
                                        <div className="partner-widget">
                                            {/* <div className="verified-badge">
                                                <i className="bi bi-patch-check-fill" />
                                                {/* <span>verified</span> */}
                                            {/* </div>  */}
                                            <div
                                                className="partner-avatar"
                                                style={{
                                                    backgroundImage: `url(${Partner1})`
                                                }}
                                            ></div>
                                            <div className="partner-details__wrapper">
                                                {/* <p className="p-name">Sakalanka Akalanka</p> */}
                                                <p className="skill-tags">
                                                    <span className="badge rounded-pill bg-light text-dark">

                                                    </span>
                                                    &nbsp;
                                                    <span className="badge rounded-pill bg-light text-dark">

                                                    </span>
                                                    &nbsp;
                                                    <span className="badge rounded-pill bg-light text-dark">

                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        {/* /item */}
                                    </div>
                                    <div className="partner-spread-right">
                                        {/* item */}
                                        <div className="partner-widget">
                                            <div className="verified-badge">
                                                <i className="bi bi-patch-check-fill" />
                                                <span></span>
                                            </div>
                                            <div
                                                className="partner-avatar"
                                                style={{
                                                    backgroundImage: `url(${Partner2})`
                                                }}
                                            ></div>
                                            <div className="partner-details__wrapper">
                                                {/* <p className="p-name">Sakalanka Akalanka sakalakalanka</p> */}
                                                <p className="skill-tags">
                                                    <span className="badge rounded-pill bg-light text-dark">
                                                        {/* #android */}
                                                    </span>
                                                    &nbsp;
                                                    <span className="badge rounded-pill bg-light text-dark">
                                                        {/* #business */}
                                                    </span>
                                                    &nbsp;
                                                    <span className="badge rounded-pill bg-light text-dark">
                                                        {/* #marketing */}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        {/* /item */}
                                        {/* item */}
                                        <div className="partner-widget">
                                            <div className="verified-badge">
                                                <i className="bi bi-patch-check-fill" />
                                                <span></span>
                                            </div>
                                            <div
                                                className="partner-avatar"
                                                style={{
                                                    backgroundImage: `url(${Partner3})`
                                                }}
                                            ></div>
                                            <div className="partner-details__wrapper">
                                                {/* <p className="p-name">Sakalanka Akalanka</p> */}
                                                <p className="skill-tags">
                                                    <span className="badge rounded-pill bg-light text-dark">
                                                        {/* #android */}
                                                    </span>
                                                    &nbsp;
                                                    <span className="badge rounded-pill bg-light text-dark">
                                                        {/* #business */}
                                                    </span>
                                                    &nbsp;
                                                    <span className="badge rounded-pill bg-light text-dark">
                                                        {/* #marketing */}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        {/* /item */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="botton-center-btn__wrapper">
                            <a href="mailto:info.marketplace@dialog.lk">
                                <button className="btn btn-primary">
                                    Find the right partner for your requirement
                                </button><br></br>
                                <span> Contact us via info.marketplace@dialog.lk </span>
                            </a>
                        </div> */}
                    </div>
                </div>
            </section>
         
            {/* / Why martketplace */}
            {/* featuring tools*/}
            <section
                className="featuring-tool common-section center-content"
                id=""
            >
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="center-details__wrapper">
                                <p className="sec-sub-title">Digitize Your Business with Marketplace</p>
                                <h2 className="sec-main-title">
                                    Transform Your Business with Marketplace
                                </h2>
                                <p className="sec-dec-wrapper">
                                    We are here to empower your business with affordable digital solutions for transformation, revenue growth, and sustainable success
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="left-details__wrapper">
                                    <img
                                        src={require('../../assets/images/betterHrLogo.svg').default}
                                        alt="product logo"
                                        srcSet=""
                                        className="feature-tool-logo"
                                    />
                                    <h2 className="sec-main-title">
                                        Modernize HR & Payroll Operations with Cloud Software
                                    </h2>
                                    <p className="sec-dec-wrapper">
                                        Revolutionize HR with Better HR for streamlined attendance, payroll, and skill development. Elevate workforce management through Marketplace access, maximizing efficiency.
                                    </p>
                                    <button className="btn btn-inline link-blue" onClick={onClickExplore}>
                                        Learn more about BetterHR <span />
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className="feature-tool-img-holder"
                                    style={{
                                        backgroundImage: `url(${betterHRImg})`
                                    }}
                                    aria-label="background image"
                                />
                            </div>
                        </div>
                        <div className="row row-gap">
                            <div className="col-md-6">
                                <div
                                    className="feature-tool-img-holder"
                                    style={{
                                        backgroundImage: `url(${myCallCenterImg})`
                                    }}
                                    aria-label="background image"
                                />
                            </div>
                            <div className="col-md-6">
                                <div className="left-details__wrapper">
                                    <img
                                        src={require('../../assets/images/S49.svg').default}
                                        alt="My Call Center"
                                        srcSet=""
                                        className="feature-tool-logo"
                                    />
                                    <h2 className="sec-main-title">
                                        Unlock Seamless Communication with My Call Center
                                    </h2>
                                    <p className="sec-dec-wrapper">
                                        My Call Center transforms mobiles into communication hubs, seamlessly integrating PABX features on the Broadsoft platform. No need for costly systems—experience unified communication effortlessly.
                                    </p>
                                    <button className="btn btn-inline link-blue" onClick={onClickExplore}>
                                        Learn more about My Call Center <span />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /featuring tools */}
            {/* featuring tools*/}
            <section className="feature-by-type common-section" id="">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row feature-type-item__wrapper">
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="feature-type-item">
                                        <div
                                            className="feature-type-img"
                                            style={{
                                                backgroundImage: `url(${Feature1})`
                                            }}
                                        />
                                        <h5 className="feature-title">Feature for Startups</h5>
                                        <p className="feature-dec">
                                            Empower your startup with our comprehensive features—extend, configure, and customize every aspect for seamless management and growth
                                        </p>
                                        <button className="btn btn-inline link-blue" onClick={onClickExplore}>
                                            View Startups Features <span />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="feature-type-item">
                                        <div
                                            className="feature-type-img"
                                            style={{
                                                backgroundImage: `url(${Feature2})`
                                            }}
                                        />
                                        <h5 className="feature-title">Feature for SME/SOHO</h5>
                                        <p className="feature-dec">
                                            Fuel medium-scale business growth with cutting-edge tools. Embrace a digital transformation, placing your company at the forefront of modern practices
                                        </p>
                                        <button className="btn btn-inline link-blue" onClick={onClickExplore}>
                                            View SME Features <span />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="feature-type-item">
                                        <div
                                            className="feature-type-img"
                                            style={{
                                                backgroundImage: `url(${Feature3})`
                                            }}
                                        />
                                        <h5 className="feature-title">Feature for Large Enterprice</h5>
                                        <p className="feature-dec">
                                            Elevate enterprise with Dialog's Marketplace Solutions. Embrace digitalization to future-proof your business with cutting-edge technologies
                                        </p>
                                        <button className="btn btn-inline link-blue" onClick={onClickExplore}>
                                            View Enterprice Features <span />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /featuring tools */}
            {/* grow your business */}
            
            <section
                    className="featuring-tool common-section center-content"
                    id="helps"
                >
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row row-gap">
                                <div className="col-md-6">
                                    <div
                                        className="feature-tool-img-holder"
                                        style={{
                                            backgroundImage: `url(${letsTalk})`
                                        }}
                                        aria-label="background image"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="left-details__wrapper" id="contact_us">
                                        <h2 className="sec-main-title">Let's Talk</h2>
                                        <p className="sec-dec-wrapper">
                                            Interested in a solution or want to find more information about
                                            our services? Contact us via{" "} <a href='mailto:info.marketplace@dialog.lk' style={{color:'black'}}>Email : info.marketplace@dialog.lk </a>, Telephone: +94 777 887 887
                                        </p>
                                        {/* <ul className="help-section-contact-list__wrapper">
                                            <li className="help-section-conntact-list-item">
                                                <div className="contacdt-icon__wrapper"> */}
                                                    {/* <i className="bi bi-email-fill" /> */}
                                                {/* </div>
                                                <span>  Email - </span>
                                                <a href="mailto:info.marketplace@dialog.lk">info.marketplace@dialog.lk</a>
                                            </li>
                                            <br></br>
                                            <li className="help-section-conntact-list-item">
                                                <div className="contacdt-icon__wrapper">
                                                    {/* <i className="bi bi-telephone-fill" /> */}
                                                {/* </div>
                                                <span>  Telephone - </span>
                                                <a href="tel:+94 777 887 887">+94 777 887 887</a>
                                            </li>
                                        </ul>  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            <section
                className="grow-your-business common-section center-content bg-idea-gradient"
                id=""
            >
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="center-details__wrapper">
                                <p className="sec-sub-title">Amplify Your Earnings</p>
                                <h2 className="sec-main-title">
                                    Are you ready to take your business to the next level?
                                </h2>
                                <p className="sec-dec-wrapper">
                                    Kick off with a hassle-free registration process.
                                </p>
                                <button className="btn btn-primary" onClick={onClickExplore}>Try Now</button>
                            </div>
                        </div>
                        <img
                            src={require('../../assets/images/scribble-highliter-2.png').default}
                            alt=""
                            srcSet=""
                            className="scribble-highlighter"
                        />
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default App;