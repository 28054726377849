export const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const REACT_APP_GOOGLE_RECAPTURE_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTURE_SITE_KEY ? process.env.REACT_APP_GOOGLE_RECAPTURE_SITE_KEY : '';
export const REACT_APP_VALID_ROLES = process.env.REACT_APP_VALID_ROLES;
export const TEST_BACKEND_URL = "http://localhost:3000";
export const MP_OLD_PORTAL_URL = process.env.REACT_APP_OLD_PORTAL_URL

export const BASEURLPATHLENGTH = 0;


export const EMP_ATT_SAVE = "Are you sure, you want to save employee attendance?";
export const EMP_ATT_UPDATE = "Are you sure, you want to update employee attendance?";
export const PAYRENT_CONSENT_SAVE = "Are you sure to save and Pay for parent consent widget ?";
export const EMPLOYEE_SENTIMENT_SAVE = "Are you sure to save and Pay for Employee sentiment widget ?";
export const CHILD_TRACKING_SAVE = "Are you sure to save and pay for location track widget?";
export const LOCATION_WMEWC = "Are you sure you want to send the new tracking for admin approvals?";
export const LOCATION_MAPPING_SAVE = "Are you sure to save and pay for location mapping widget?";
export const LOCATION_MAPPING_UPDATE = "Are you sure to update and pay for location mapping widget?";
export const NEW_GENIE_SAVE = "Are you sure to create a new Genie Account?";
export const LOCATION_TRACKING_UPDATE = "Are you sure to update and pay for location track widget?";
export const RESUBMIT_LOCATION_TRACKING_UPDATE = "Are you sure you want to resubmit the session for approval?";
export const CHILD_TWO_FACTOR_SMS = "Are you sure to save and Pay for Two Factor Authentication Through SMS widget ?";
export const WEATHERALERT = "Are you sure to save and Pay for weather alert widget ?";
export const AIRINDEXALERT = "Are you sure to save and Pay for weather alert widget ?";
export const QUICKSURVEY = "Are you sure you want to pay for this survey?";
export const QUICKSURVEY_UPDATE = "Are you sure you want to update the status of Quick Survey ?";
export const SMSCONFIG_SAVE = "Are you sure to save SMS vote?";
export const SMSCONFIG_UPDATE = "Are you sure to update the SMS vote?";
export const TWO_FACTOR_USSD = "Are you sure to save and Pay for Two Factor Authentication Through USSD widget ?";
export const QUICK_CAMPAIGN = "Are you sure you want to pay for this campaign?";
export const QUICK_CAMPAIGN_UPDATE = "Are you sure to update your quick campaign ?";
export const NEW_EZCASH_SAVE = "Are you sure to create a new eZ Cash Account? ";
export const NEW_SPIN_WHEEL_SAVE = "Are you sure to create a new spin Request? ";
export const NEW_TRIBE_ID_SAVE = "Are you sure to create a new DigiEdu Verifiable Credentials Request? ";
export const LOCATION_OD_WC_EDIT = "Are you sure you want to resubmit the session for approval? ";
export const NEW_LOCATION_OD_WC_SAVE = "Are you sure you want to send the new tracking for admin approvals?";
export const PORT_SAVE = "Are you sure, you want to select this short code?";
export const LONG_NUMBER_SAVE = "Are you sure, you want to add this long number?";
export const NEW_ENT_AGENT_SAVE = "Do you want to create new agent?";
export const META_EVENT_SAVE = "Are you sure to save meta event details?"

export const DRAFT = "Draft";
export const REDRAFT = "Redraft";
export const REJECTED = "Rejected";
export const SUBMITTED = "Submitted";
export const RESUBMITTED = "Resubmitted";
export const STOPPED = "Stopped";

export const COMMON_TENANT_ID = process.env.REACT_APP_COMMON_TENANT_ID;
export const MARKETPLACEAUTH = process.env.REACT_APP_MARKETPLACEAUTH;
export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;

export const SUB_DIRECTORY = "partnerprogram";
