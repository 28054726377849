import React, { useState, useRef, useEffect } from "react";
import SimpleReactValidator from 'simple-react-validator';
import { postJoinPartnerProgram, getDistricts } from '../../services/app.service';
import { message } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import * as constants from '../../constants';
import { useNavigate } from 'react-router-dom';

const PartnerApply = () => {
    let navigate = useNavigate();
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [email, setEmail] = useState('');
    const [experience, setExperience] = useState('');
    const [motivates, setMotivates] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitButtonEnable, setSubmitButtonEnable] = useState(false);
    const [recaptchaKey, setRecaptchaKey] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [districtList, setDistrictList] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const recaptchaRef = useRef(null);

    const [validator] = useState(new SimpleReactValidator({
        messages: {
            required: 'You can’t keep this as empty',
            min: 'The :attribute is too short',
            max: 'The :attribute is too lengthy',
            numeric: 'The :attribute is invalid.'
        },
        validators: {
            dialog: {
                message: 'The :attribute is invalid.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^([0]{1}[7]{1}[764]{1}\d{7}|[7]{1}[764]{1}\d{7})$/) && params.indexOf(val) === -1
                },
            },
            alpha_num_underscore: {
                message: 'The :attribute is invalid.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9_]*$/) && params.indexOf(val) === -1
                },
            },
            alpha_num_underscore_space: {
                message: 'The :attribute is invalid.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9_\s]*$/) && params.indexOf(val) === -1
                },
            },
            alpha_num_underscore_dash_space: {
                message: 'The :attribute is invalid.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9_\s-]*$/) && params.indexOf(val) === -1
                },
            },
            address: {
                message: 'The :attribute is invalid.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[a-zA-Z0-9.,/\s-]*$/) && params.indexOf(val) === -1
                },
            }
        }
    }));

    const getDistrictsForDropdown = () => {
        getDistricts().then((response) => {
            if (response) {
                setDistrictList(response.data);
            }
        }).catch(err => {
            // message.error("Partner join request failed");
            // if (err.response) {
            //     console.log(err.response)
            // } else {
            //
            // }
        });
    }

    useEffect(() => {
        getDistrictsForDropdown();
    }, []);

    const onChangeName = (e) => {
        setName(e.target.value);
        validator.showMessageFor('name');
    }

    const onChangeAddress = (e) => {
        setAddress(e.target.value);
        validator.showMessageFor('address');
    }

    const onChangeContactNumber = (e) => {
        setContactNumber(e.target.value);
        validator.showMessageFor('contact');
    }

    const onChangeFirstName = (e) => {
        setFirstName(e.target.value);
        validator.showMessageFor('firstName');
    }

    const onChangeLastName = (e) => {
        setLastName(e.target.value);
        validator.showMessageFor('lastName');
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
        validator.showMessageFor('email');
    }

    const onChangeExperience = (e) => {
        setExperience(e.target.value);
        validator.showMessageFor('content');
    }

    const onChangeMotivate = (e) => {
        setMotivates(e.target.value);
        validator.showMessageFor('description');
    }

    const onChangeDistrict = (e) => {
        setSelectedDistrict(e.target.value);
        validator.showMessageFor('district');
    }

    const clearFields = (e) => {
        setName('');
        setAddress('');
        setEmail('');
        setContactNumber('');
        setExperience('');
        setMotivates('');
        setRecaptchaKey('');
        setFirstName('');
        setLastName('');
        setSelectedDistrict('');
        setSubmitButtonEnable(false);
        // recaptchaRef.current.reset();
    }

    // const reCaptchaChange = (value) => {
    //     console.log("Captcha value:", value);
    //     setRecaptchaKey(value);
    //     setSubmitButtonEnable(true);
    // }

    const onSubmitUser = async () => {
        try {
            setSubmitLoading(true);
            if (validator.allValid()) {
                let request = {
                    "firstName": firstName,
                    "lastName": lastName,
                    "address": address,
                    "district": selectedDistrict,
                    "email": email,
                    "contactNumber": contactNumber.length === 10 ? contactNumber : '0' + contactNumber,
                    "experience": experience,
                    "motivates": motivates,
                    "key": recaptchaKey
                };
                postJoinPartnerProgram(request).then((response) => {
                    console.log("response: " + JSON.stringify(response))
                    if (response) {
                        // console.log(response.data.toString(0));
                        message.success(response.data.toString(0));
                        clearFields();
                    }
                }).catch(err => {
                    setSubmitLoading(false);
                    message.error("Partner join request failed");
                    if (err.response) {
                        console.log(err.response)
                    }
                });
            } else {
                validator.showMessages();
                //setSubmitLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const renderButton = () => {
        // if (submitLoading) {
        //     return (
        //         <button
        //             type="button"
        //             className="btn btn-primary"
        //         >
        //             <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        //         </button>
        //     )
        // } else {
            return (
                <div className="form__wrapper-footer">
                    <button className="btn btn-secondary" onClick={clearFields}>Clear Form</button>
                    <button 
                        className="btn btn-primary" 
                        onClick={onSubmitUser} 
                        disabled={firstName === '' || lastName === '' || 
                            address === '' || selectedDistrict === '' || contactNumber === '' || 
                            experience === '' || email === '' || motivates === '' || !validator.allValid()}
                    >Save &amp; Submit
                    </button>
                </div>
            )
        //}
    }

    const onBack = () => {
        navigate('/partner');
    }

    const renderForm = () => {
        const options = districtList.map((item, index) => {
            return (
                <option key={index} disabled={index === -1 ? true : null}>{item.name}</option>
            );
        })
        return (
            <>
                <section className="registration-form common-section center-content" id htmlFor>
                    <div className="container-fluid">
                        <div className="container">
                            <div className="center-details__wrapper">
                                <p className="sec-sub-title">Welcome</p>
                                <h2 className="sec-main-title">Thank you for interest in Marketplace partner program</h2>
                                <p className="sec-dec-wrapper">Please provide us with information about yourself, your company, and areas of interest.
                                    {/* If you have any questions or need help, you can refer to these enrollment instructions or request support here. */}
                                </p>
                            </div>
                            <div className="form__wrapper">
                                <div className="mb-4 form-input__wrapper left-align">
                                    <label htmlFor="firstName" className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                        onChange={onChangeFirstName}
                                        value={firstName}
                                        placeholder="Your first name" />
                                    <small className="errorText">{validator.message('firstName', firstName, 'required|min:3|max:25|alpha_space')}</small>
                                </div>
                                <div className="mb-4 form-input__wrapper left-align">
                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                        onChange={onChangeLastName}
                                        value={lastName}
                                        placeholder="Your last name" />
                                    <small className="errorText">{validator.message('lastName', lastName, 'required|min:3|max:25|alpha_space')}</small>
                                </div>
                                <div className="mb-4 form-input__wrapper left-align">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address"
                                        value={address}
                                        onChange={onChangeAddress}
                                        placeholder="Your Address" />
                                    <small className="errorText">{validator.message('address', address, 'required|min:3|max:250|address')}</small>
                                </div>
                                <div className="mb-4 form-input__wrapper left-align">
                                    <label htmlFor="firstName" className="form-label">District</label>
                                    <select
                                        name="sector"
                                        className="form-control"
                                        value={selectedDistrict}
                                        onChange={onChangeDistrict}
                                    >
                                        <option value="" >Select Your District</option>
                                        {options}
                                    </select>
                                    <small className="errorText">{validator.message('district', selectedDistrict, 'required')}</small>
                                </div>
                                <div className="mb-4 form-input__wrapper left-align">
                                    <label htmlFor="contact" className="form-label">Contact number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={contactNumber}
                                        onChange={onChangeContactNumber}
                                        id="contact"
                                        placeholder="07xxxxxxxx/7xxxxxxxx" />
                                    <small id="email" className="form-text text-muted ">Please enter a valid Dialog mobile number </small>
                                    <small className="errorText">{validator.message('contact', contactNumber, 'required|numeric|dialog')}</small>
                                </div>
                                <div className="mb-4 form-input__wrapper left-align">
                                    <label htmlFor="email" className="form-label">Email address</label>
                                    <input type="email"
                                        className="form-control"
                                        id="email"
                                        value={email}
                                        onChange={onChangeEmail}
                                        placeholder="email@domain.com" />
                                    <small className="errorText">{validator.message('email', email, 'required|email|min:3|max:100')}</small>
                                </div>
                                <div className="mb-4 form-input__wrapper left-align">
                                    <label htmlFor="experience" className="form-label">Describe previous experience as a partner </label>
                                    <textarea
                                        className="form-control"
                                        id="experience"
                                        rows={3}
                                        value={experience}
                                        onChange={onChangeExperience}
                                        defaultValue={""} />
                                    <small className="errorText">{validator.message('content', experience, 'required|min:3|max:250|alpha_space')}</small>
                                </div>
                                <div className="mb-4 form-input__wrapper left-align">
                                    <label htmlFor="motivates" className="form-label">What motivates you to join as a partner?</label>
                                    <textarea
                                        className="form-control"
                                        id="motivates"
                                        value={motivates}
                                        onChange={onChangeMotivate}
                                        rows={3}
                                        defaultValue={""} />
                                    <small className="errorText">{validator.message('description', motivates, 'required|min:3|max:250|alpha_space')}</small>
                                </div>
                                {/* <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={constants.REACT_APP_GOOGLE_RECAPTURE_SITE_KEY}
                                    onChange={reCaptchaChange}
                                /> */}
                                <br />
                                {renderButton()}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

    return (
        <React.Fragment>
            <section className="main-hero inner-hero">
                <div className="container-fluid">
                    <div className="container">
                        <div className="hero__wrapper">
                            <div className="hero-content">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-8 text">
                                        <br />
                                        <button
                                            className="btn back-btn"
                                            onClick={onBack}
                                        > <span /> Go back </button>
                                        <br /><br />
                                        <h1>Partner Registration Form</h1>
                                        <br />
                                        <p>Thank you for your interest in the Marketplace Partner Program.</p>
                                    </div>
                                    <div className="col-md-4 cover">
                                        <img src={require("../../assets/images/bg-cover-partner-form.4245f58a.webp")} alt="" srcSet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {renderForm()}
        </React.Fragment>
    );
}

export default PartnerApply;