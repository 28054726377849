import React, { useEffect, useState } from 'react';
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { getApps, getBusinessTypes } from '../services/app.service';
import * as Constants from '../constants';

const Footer = () => {
    const [widgets, setWidgets] = useState([]);
    const [businessTypes, setBusinessTypes] = useState([]);
    const [featurewidgets, setFeatureWidgets] = useState([]);
    const [shadeColors, setShadeColors] = useState(['red', 'green', 'blue', 'primary']);


    useEffect(() => {
        getApps().then((response) => {
            setWidgets(response.data.data.widgets);
            setFeatureWidgets(response.data.data.featuredWidgets);
        }).catch((error) => {

        })
    }, []);

    useEffect(() => {
        getBusinessTypes().then((response) => {
            setBusinessTypes(response.data.data);
        }).catch((error) => {

        })
    }, []);

    const onVisitApp = (widgetId: string) => {
        window.open(`${Constants.PORTAL_URL}/widget/${widgetId}`, "_blank") || window.location.replace(`${Constants.PORTAL_URL}/widget/${widgetId}`)
    }

    const onDiscover = () => {
        window.open(`${Constants.PORTAL_URL}/apps/wizard`, "_blank") || window.location.replace(`${Constants.PORTAL_URL}/apps/wizard`)
    }

    const onVisitPortal = (uuid: string) => {
        window.open(`${Constants.PORTAL_URL}/apps/${uuid}`, "_blank") || window.location.replace(`${Constants.PORTAL_URL}/apps/${uuid}`)
    }

    return (
        <footer className="idea-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="row">
                            <div className="svg-wrapper">
                                <img
                                    src={require('../assets/images/branding/mp-primary-logo.svg').default}
                                    alt=""
                                />
                            </div>
                            {/*/svg wrapper*/}
                            <div className="content-wrapper">
                                <p>
                                    Marketplace is a platform presented by Dialog Enterprise, providing a self-service model for businesses to access and utilize digital tools with zero up-front costs, leveraging mobile operator billing capability.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*End col*/}
                    <div className="col-md-3 footer-list">
                        <h4>Products</h4>
                        <ul>
                            {/* <li>
                                <a href=""> eSMS</a>
                            </li>
                            <li>
                                <a href=""> Managed Campaign</a>
                            </li>
                            <li>
                                <a href=""> Better HR</a>
                            </li>
                            <li>
                                <a href=""> Corporate Ring Back Tone(CRBT) </a>
                            </li> */}
                            {Array.isArray(featurewidgets) && featurewidgets.map((value: any, index: number) => {
                                return (
                                    <li key={index} onClick={() => onVisitApp(value.uuid)}>
                                        <a href="javascript:void(0);">{value.title}</a>
                                    </li>
                                )
                            })}

                            <li className="button btn btn-inline link-blue">
                                <a href="" onClick={() => onDiscover()}>
                                    {" "}
                                    View more <span />
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/*End col*/}
                    <div className="col-md-3 footer-list">
                        <h4>Solutions</h4>
                        <ul>
                            {/* <li>
                                <a href=""> Startup</a>
                            </li>
                            <li>
                                <a href=""> Small & Medium Businesses</a>
                            </li>
                            <li>
                                <a href=""> Large Enterprise</a>
                            </li> */}
                            {Array.isArray(businessTypes) && businessTypes.map((value: any, index: number) => {
                                return (
                                    <li key={index} onClick={() => onVisitPortal(value.uuid)}>
                                        <a href="javascript:void(0);">{value.businessType}</a>
                                    </li>
                                )
                            })}
                            {/* <li className="button btn btn-inline link-blue">
                                <a href="">
                                    {" "}
                                    View more <span />
                                </a>
                            </li> */}
                        </ul>
                        {/*list end*/}
                        {/* <div className="second-level-wrapper">
                            <h4>About Marketplace</h4>
                            <ul>
                                <li>
                                    <a href="mailto:info.marketplace@dialog.lk">Contact Us</a>
                                </li>
                                <li>
                                    <a href="mailto:support.marketplace@dialog.lk"> Customer Support</a>
                                </li>
                                {/* <li>
                                    <a href=""> Report a Abuse</a>
                                </li> */}
                        {/* </ul> */}
                        {/*list end*/}
                        {/* </div> */}
                    </div>
                    {/*End col*/}
                    <div className="col-md-3 footer-list">
                        <h4>Martkeplace Partner program</h4>
                        <ul>
                            <li>
                                <a href="/partner"> what is the partner program</a>
                            </li>
                            <li>
                                <a href="/partner-apply">Become a partner</a>
                            </li>
                            <li>
                                <a href="/partner-list"> Find a certified partner</a>
                            </li>
                        </ul>
                        <h4>About Marketplace</h4>
                        <ul>
                            <li>
                                <a href="mailto:marketplace.techsupport@dialog.lk">Contact Us</a>
                            </li>
                            <li>
                                <a href="mailto:support.marketplace@dialog.lk"> Customer Support</a>
                            </li>
                            <li>
                                <a href="mailto:marketplace.techsupport@dialog.lk"> Technical Support</a>
                            </li>
                        </ul>
                        {/*list end*/}
                    </div>
                    {/*End col*/}
                </div>
            </div>
            {/*/ container*/}
            {/*bottom footer*/}
            <div className="container-fluid line">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 list">
                            <div className="row">
                                <ul>
                                    <li>
                                        {" "}
                                        <a href="https://d2xt8w8fh2bnjt.cloudfront.net/qa/userguides/Terms.pdf" target="_blank">Terms &amp; Conditions</a>{" "}
                                    </li>
                                    <li>
                                        {" "}
                                        <a href="https://d2xt8w8fh2bnjt.cloudfront.net/qa/userguides/Privacy+Policy.pdf" target="_blank">Privacy</a>{" "}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/*/ col*/}
                        <div className="col-md-4">
                            <p>©&nbsp;Copyright protected . Engineered by Ideamart </p>
                            <img
                                src={require('../assets/images/branding/dialogenterprise_logo.png').default}
                                alt=""
                                srcSet=""
                                style={{ maxHeight: 25 }}
                            />
                        </div>
                        <div className="col-md-4 ">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/Marketplace.Ideabiz" target="_blank" >
                                        <i className="bi bi-facebook" style={{ fontSize: '25px' }} />
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="">
                                        <i className="bi bi-twitter-x" />
                                    </a>
                                </li> */}
                                <li>
                                    <a href="https://instagram.com/enterprise.marketplace?igshid=YTQwZjQ0NmI0OA==" target="_blank">
                                        <i className="bi bi-instagram" style={{ fontSize: '25px' }} />
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="">
                                        <i className="bi bi-linkedin" />
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/*/ bottom footer*/}
        </footer>
    );
}

export { Footer };