import axios from 'axios';

var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        let token: string | null = localStorage.getItem('kc_token') ? localStorage.getItem('kc_token') : 'token';
        // const token = window.accessToken ? window.accessToken : 'token';
        // const isTokenExpired = window.isTokenExpired;
        config!.headers!['Authorization'] = 'Bearer ' + token;
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use((response) => {
    return response
}, function (error) {
    return Promise.reject(error);
});
export default axiosInstance;