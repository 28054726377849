import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import EllipsisText from "react-ellipsis-text";
import { getMaList } from '../../services/app.service';
import * as CONSTANTS from '../../constants';
import avatar from '../../assets/images/avatar.svg';

function PartnerList() {
    let navigate = useNavigate();
    const [search_term, setSearchTerm] = useState('');
    const [maList, setMaList] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const loadMaList = (offset, limit) => {
        getMaList(search_term !== '' ? search_term : 'ALL', offset, limit).then((response) => {
            console.log("##1", response.data);
            setMaList([...maList, ...response.data]);
        }).catch((error) => {
            console.log(error);
        });
    }

    const searchMaList = (offset, limit) => {
        setOffset(0);
        getMaList(search_term !== '' ? search_term : 'ALL', offset, limit).then((response) => {
            setMaList(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }


    useEffect(() => {
        loadMaList(offset, limit);
    }, [offset]);

    useEffect(() => {
        searchMaList(offset, limit);
    }, [search_term]);

    const onLoadMore = () => {
        setOffset(offset + 10);
    }

    const onChangeSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    }

    const onBack = () => {
        navigate(-1);
    }

    const renderMAList = () => {
        if (Array.isArray(maList) && maList.length > 0) {
            return (
                maList.map((value, index) => {
                    if (value !== null && typeof value !== 'undefined') {
                        return (
                            <Link className="partner-widget" to={`/partners/${value.userName}/${value.uuid}`} key={index}>
                                <div className="verified-badge">
                                    <i className="bi bi-patch-check-fill" />
                                    {/* <span>Verified</span> */}
                                </div>
                                <div className="partner-avatar" style={{ backgroundImage: `url(${((value.Profile !== null && value.Profile.profilePic !== null) ? value.Profile.profilePic : `url(${avatar})`)})` }}>
                                </div>
                                <div className="partner-details__wrapper">
                                    <p className="p-name">{value.firstName} {value.lastName}</p>
                                    {(value.Profile !== null && value.Profile.specification !== null) ? <p className="skill-tags"><span className="badge rounded-pill bg-light text-dark"><EllipsisText text={value.Profile.specification} length={"40"} /></span></p> : <p></p>}
                                </div>
                            </Link>
                        )
                    }
                })
            )
        } else {
            return (
                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <i>“No marketing agent found.”</i>
                </div>
            )
        }
    }

    return (
        <>
            <section className="main-hero inner-hero">
                <div className="container-fluid">
                    <div className="container">
                        <div className="hero__wrapper">
                            <div className="hero-content">
                            <br />
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 text">
                                        <button
                                            onClick={() => onBack()}
                                            className="btn back-btn"> <span /> Go back </button>
                                            <br /><br />
                                        <h1>Dialog Marketplace Partner Directory</h1>
                                        <br />
                                        <p>Introducing our Official Dialog Marketplace Partners</p>
                                    </div>
                                    <div className="col-md-5 cover">
                                        <img src={require("../../assets/images/bg-cover-partner-direct.d49311b2.webp")} alt="cover image goes here" srcSet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* / top hero section */}
            {/* What is */}
            <section className="main-partner-directory common-section center-content" id htmlFor>
                <div className="container-fluid">
                    <div className="container">
                        <div className="center-details__wrapper">
                            <p className="sec-sub-title">Our sales partner network</p>
                            <h2 className="sec-main-title">Search Dialog Marketplace Partners</h2>
                        </div>
                        <div className="partner-grid__wrapper row">
                            <dev className="main-search__wrapper">
                                {/* search bar */}
                                <div className="icon-inside search__bar mb-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Dialog Marketplace Partners by Name"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        spellCheck="false"
                                        data-ms-editor="true"
                                        autoComplete="off"
                                        value={search_term}
                                        onChange={onChangeSearchTerm}
                                    />
                                    <i className="bi bi-search" />
                                </div>
                                <br />
                            </dev>
                            <div className="partner-grid">
                                {renderMAList()}
                            </div>
                            {(Array.isArray(maList) && maList.length > offset) && <div className="partner-grid-footer__wrapper">
                                <button onClick={onLoadMore} className="btn btn-primary">Load more</button>
                            </div>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PartnerList;