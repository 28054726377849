import React, { useEffect, useState } from 'react';
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import RoutesLayout from './RoutesLayout';
import Home from '../pages/home/Home';
import PartnerList from '../pages/partner/partner-list.screen';
import PartnerApply from '../pages/partner/partner-apply.screen';
import PartnerProgram from '../pages/partner/partner-program.screen';
import SinglePartner from '../pages/partner/single-partner.screen';

type AppRoutesProps = {
}

const RouteHandler = ({ }: AppRoutesProps) => {
  const [login, setLogin] = useState(false);

  return (
    <>
      <Routes>
        <Route path={'/'} element={<RoutesLayout />} >
          <Route path="/" element={<Home />} />
          <Route path="/partner-list" element={<PartnerList />} />
          <Route path="/partner-apply" element={<PartnerApply />} />
          <Route path="/partner" element={<PartnerProgram />} />
          <Route path="/partners/:username/:id" element={<SinglePartner />} />
        </Route>
      </Routes>
    </>
  );
}

export default RouteHandler;