import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as CONSTANTS from '../../constants';
import benefit01 from '../../assets/images/benefit-01.png';
import benefit02 from '../../assets/images/benefit-02.png';
import benefit03 from '../../assets/images/benefit-03.png';
import benefit04 from '../../assets/images/benefit-04.png';
import onbStep01 from '../../assets/images/onb-step-1.svg';
import onbStep02 from '../../assets/images/onb-step-2.svg';
import onbStep03 from '../../assets/images/onb-step-3.svg';
import onbStep04 from '../../assets/images/onb-step-4.svg';
import howStep01 from '../../assets/images/how-step-1.svg';
import howStep02 from '../../assets/images/how-step-2.svg';
import howStep03 from '../../assets/images/how-step-3.svg';


function PartnerProgram(props) {

    let navigate = useNavigate();

    const onBack = () => {
        navigate(-1);
    }

    return (
        <>
            <section className="main-hero secondary-hero">
                <div className="container-fluid">
                    <div className="container">
                        <div className="hero__wrapper">
                            <div className="hero-content">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 text">
                                        <br />
                                        <h1>Dialog Marketplace <br /> Partner Program</h1>
                                        <br />
                                        <p>Amaze your clients with the feature-packed marketplace platform that is easy to build with.</p>
                                        <br />
                                        <div className="button-group">
                                            <Link className="btn btn-primary btn-lg" to={'/partner-apply'}>Become a Partner</Link>
                                            <br /><br />
                                            <button
                                                onClick={() => navigate('/partner-list')}
                                                className="btn btn-inline btn-lg primary">View All Dialog Marketplace Partners <span />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-5 cover">
                                        <img src={require('../../assets/images/bg-cover-partner-prog.webp')} alt="" srcSet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* / top hero section */}
            {/* What is */}
            <section className="what-is common-section center-content bg-line-pattern" id htmlFor>
                <div className="container-fluid">
                    <div className="container">
                        <div className="center-details__wrapper">
                            <p className="sec-sub-title">What is Dialog Marketplace Partner Progam</p>
                            <h2 className="sec-main-title">Earn while helping other businesses to grow</h2>
                            <p className="sec-dec-wrapper">Dialog Marketplace Partner Program is the Sales Partner Network  designed to take Dialog's enterprise solutions to wider audiences. The Sales Partner Network is responsible for reselling Dialog Marketplace enterprise solutions to SOHO & SME businesses. The program is solely managed by Dialog Axiata PLC.  Partners will act as liaisons between Dialog and enterprise business entities.</p>
                        </div>
                        <div className="stats-details__wrapper row">
                            {/* item */}
                            <div className="stats-item col-md-4">
                                <h1 className="stats-title">220+</h1>
                                <p className="stats-dec">Dialog Marketplace Partner Network</p>
                            </div>
                            {/* /item */}
                            {/* item */}
                            <div className="stats-item col-md-4">
                                <h1 className="stats-title">3+</h1>
                                <p className="stats-dec">Dialog Marketplace Partner Progam Weekly Sessions</p>
                            </div>
                            {/* /item */}
                            {/* item */}
                            <div className="stats-item col-md-4">
                                <h1 className="stats-title">5500+</h1>
                                <p className="stats-dec">Enterprises Empowered By Dialog Marketplace Partners</p>
                            </div>
                            {/* /item */}
                        </div>
                    </div>
                </div>
            </section>
            {/* / What is */}
            {/* Why become a partner */}
            <section className="why-marketplace common-section left-content bg-circle-pattern" id htmlFor>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="left-details__wrapper">
                                    <p className="sec-sub-title">Why partner with Dialog Marketplace Platform</p>
                                    <h2 className="sec-main-title">This sales partner role offers unique perks and incentives:</h2>
                                    <p className="sec-dec-wrapper">Marketplace Partners are empowered to promote and resell the platform to potential customers and provide rapid, friendly and tailored solutions.</p>
                                    <p className="sec-dec-wrapper">Partner will conduct product sales pitches, platform demonstrations and customer support for the enterprise customers.</p>
                                    <button className="btn btn-inline link-blue" onClick={() => navigate('/partner-apply')}>Become a partner & enjoy the benefits <span /></button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="two-col">
                                    {/* item */}
                                    <li className="one-col">
                                        <div className="image__wrapper" style={{ backgroundImage: `url(${benefit01})` }} />
                                        <div className="text__wrapper">
                                            <h5>Attractive commission scheme</h5>
                                            <ul>
                                                <li>Build your own revenue stream as a partner of the program</li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* /item */}
                                    {/* item */}
                                    <li className="one-col">
                                        <div className="image__wrapper" style={{ backgroundImage: `url(${benefit02})` }} />
                                        <div className="text__wrapper">
                                            <h5>Island-wide recognition as an official Dialog Marketplace Partner</h5>
                                            <ul>
                                                <li>Expand your network by connecting with SME businesses</li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* /item */}
                                    {/* item */}
                                    <li className="one-col">
                                        <div className="image__wrapper" style={{ backgroundImage: `url(${benefit03})` }} />
                                        <div className="text__wrapper">
                                            <h5>Get exclusive access to resell marketplace products & services </h5>
                                            <ul>
                                                <li>Special Training Programs to enhance your selling skills</li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* /item */}
                                    {/* item */}
                                    <li className="one-col">
                                        <div className="image__wrapper" style={{ backgroundImage: `url(${benefit04})` }} />
                                        <div className="text__wrapper">
                                            <h5>Marketing materials from Dialog</h5>
                                            <ul>
                                                <li>Opportunity to work closely with Dialog</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* / Why become a partner */}
            {/* Being partner */}
            <section className=" being-partner common-section center-content bg-idea-gradient" id htmlFor>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="center-details__wrapper">
                                <p className="sec-sub-title">What’s being a Dialog Marketplace Partner all about: </p>
                                <h2 className="sec-main-title" style={{ color: 'black' }}>Dialog Marketplace partners are an integral part of our sales force community. They play a key role in:</h2>
                                <p className="sec-dec-wrapper" style={{ color: 'white' }}>You don’t simply become a partner of Marketplace, rather we collaborate to ensure that your business is well positioned, trained and equipped with the knowledge, tools and capabilities to deliver high-performance, beautifully designed marketplaces for your clients.</p>
                                <p className="sec-dec-wrapper" style={{ color: 'white' }}>We will work with you to deliver success and ensure a significant contribution to your sales, while also providing you with ongoing recurring revenue through licence fee rebates.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* / Being partner */}
            {/* After register */}
            <section className="after-registered common-section center content" id htmlFor>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="center-details__wrapper">
                                <p className="sec-sub-title">Onboarding Process</p>
                                <h2 className="sec-main-title">What happens after you have shown your interest in becoming a Dialog Marketplace partner</h2>
                                <p className="sec-dec-wrapper">Learn about how the Dialog Marketplace Partner selection process works</p>
                            </div>
                        </div>
                        <div className="row">
                            <dev className="onboarding-steps__wrapper">
                                <div className="onboarding-step-item card">
                                    <div className="step-img" style={{ backgroundImage: `url(${onbStep01})` }} />
                                    <h5 className="step-title">Enroll</h5>
                                    <p className="feature-dec">Sign Up to the Marketplace Partner Program using the form on our Dialog Marketplce website</p>
                                </div>
                                <div className="onboarding-step-item card">
                                    <div className="step-img" style={{ backgroundImage: `url(${onbStep02})` }} />
                                    <h5 className="step-title">Shortlisting Phase</h5>
                                    <p className="feature-dec">Skim through all applicants and filter out the best potential candidates</p>
                                </div>
                                <div className="onboarding-step-item card">
                                    <div className="step-img" style={{ backgroundImage: `url(${onbStep03})` }} />
                                    <h5 className="step-title">E-Meet</h5>
                                    <p className="feature-dec">Conduct 30-minute casual chat via MS Teams with the shortlisted partners </p>
                                </div>
                                <div className="onboarding-step-item card">
                                    <div className="step-img" style={{ backgroundImage: `url(${onbStep04})` }} />
                                    <h5 className="step-title">Onboarding</h5>
                                    <p className="feature-dec">Sending out the introduction emails to selected participants. Account creation and agreement sign-off and Dialog Vendor registration (Training and Development, Provide Trainings about the core platform and built-in widgets. Clarify any issues they might have to run the business smoothly)</p>
                                </div>
                            </dev>
                        </div>
                    </div>
                </div></section>
            {/* /After register */}
            {/* Partner responsibility */}
            <section className="partner-responsibility common-section center content" id htmlFor>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="center-details__wrapper">
                                <p className="sec-sub-title">How it works</p>
                                <h2 className="sec-main-title">Taking an Enterprise in a journey</h2>
                                <p className="sec-dec-wrapper">How can you convert a potential lead to a business</p>
                            </div>
                        </div>
                        <div className="row">
                            <dev className="onboarding-steps__wrapper">
                                <div className="onboarding-step-item card">
                                    <div className="step-img" style={{ backgroundImage: `url(${howStep01})` }} />
                                    <h5 className="step-title">Attain</h5>
                                    <p className="feature-dec">Get attention from potential clients via Social Media or known networks (Pitch, Demonstrate the capability of the Dialog Marketplace and onboard enterprises).</p>
                                </div>
                                <div className="onboarding-step-item card">
                                    <div className="step-img" style={{ backgroundImage: `url(${howStep02})` }} />
                                    <h5 className="step-title">Resell</h5>
                                    <p className="feature-dec">Get the enterprises to use the platform which will help them enhance and improve their businesses</p>
                                </div>
                                <div className="onboarding-step-item card">
                                    <div className="step-img" style={{ backgroundImage: `url(${howStep03})` }} />
                                    <h5 className="step-title">Support</h5>
                                    <p className="feature-dec">Provide support when enterprise require and keep in touch with them for the full life cycle of the business </p>
                                </div>
                            </dev>
                        </div>
                    </div>
                </div>
            </section>
            {/* / Testimonial */}
            {/* grow your business */}
            <section className="Join-as-partner common-section center-content bg-purple-shade" id htmlFor>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="center-details__wrapper">
                                <p className="sec-sub-title">Be accredited</p>
                                <h2 className="sec-main-title" style={{color:'black'}}>Dialog  Marketplace Partner Program</h2>
                                <p className="sec-dec-wrapper" style={{color:'#4E286D'}}>If you are an exciting indivudial or a company who is interested in enterprise solutions reselling, express your interest by enrolling. </p>
                                <button
                                    onClick={() => navigate('/partner-apply')}
                                    className="btn btn-primary"
                                >Interested? Apply Here!</button>
                            </div>
                        </div>
                        {/* <img src="/public/assets/images/scribble-highliter-2.png" alt="" srcset="" class="scribble-highlighter"> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default PartnerProgram;