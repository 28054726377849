import React, { useState, useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Skeleton } from 'antd';
import EllipsisText from "react-ellipsis-text";
import Moment from 'moment';
import { Link, useNavigate } from "react-router-dom";
import { getMaDetails, getMaList } from '../../services/app.service';
import * as Constants from '../../constants';
import avatar from '../../assets/images/avatar.svg';

function SinglePartner() {
    let navigate = useNavigate();
    const [maDetails, setMaDetails] = useState(null);
    const [maList, setMaList] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const fetchMaDetails = () => {
        var uuid = window.location.pathname.split('/')[Constants.BASEURLPATHLENGTH + 3];

        getMaDetails(uuid).then((response) => {
            // console.log("##1", response.data);
            setMaDetails(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    const loadMaList = (offset, limit) => {
        getMaList('ALL', offset, limit).then((response) => {
            // console.log("##1", response.data);
            setMaList(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        fetchMaDetails();
        loadMaList(offset, limit);
    }, []);

    const onOpenContact = (email) => {
        // window.open(`mailto:${email}?subject=Subject&body=Body%20goes%20here`);
        window.location = `mailto:${email}`;
    }

    const onBack = () => {
        navigate(-1);
    }

    if (maDetails !== null) {
        return (
            <>
                <section className="main-hero inner-hero">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="hero__wrapper">
                                <div className="hero-content">
                                    <div className="row">
                                        <div className="text">
                                            <br /> <br /> <br />
                                            <button
                                            style={{marginLeft: '470px'}}
                                                className="btn back-btn"
                                                onClick={() => onBack()}
                                            > <span /> Go back
                                            </button>
                                            <br /><br />
                                            <div className="profile-name__wrapper">
                                                <h1 style={{display: 'flex'}}>{maDetails.firstName} {maDetails.lastName}</h1>
                                                {/* verified badge */}
                                                {/* <span className="verified-badge">
                                                    <i className="bi bi-patch-check-fill"/> */}
                                                    {/* <span style={{ marginLeft: '5px' }}>Verified</span> */}
                                                {/* </span>  */}
                                                {/* / verified badge */}
                                            </div>
                                            <p style={{display: 'flex'}}><i>“{((maDetails.Profile !== null && maDetails.Profile.selfIntro !== null) ? maDetails.Profile.selfIntro : '...')}”</i>
                                            </p>
                                        </div>
                                        <div className="cover profile-cover">
                                            <div className="profile-image__placeholder" style={{ backgroundImage: `url(${((maDetails.Profile !== null && maDetails.Profile.profilePic !== null) ? maDetails.Profile.profilePic : '')})` }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* / top hero section */}
                {/* What is */}
                <section className="partner-profile-content common-section left-content" id htmlFor>
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 ">
                                    <div className="partner-details__wrapper">
                                        {/* item */}
                                        <div className="partner-specializations__wrapper">
                                            <div className="feature-icon-placeholder" style={{ backgroundImage: `url(/${Constants.SUB_DIRECTORY}/images/icons/specialization.webp)` }} />
                                            <h5 className="spec-title">Specializations</h5>
                                            {/* <span className="skill-tags"><span className="badge rounded-pill bg-light text-dark">android</span>&nbsp;<span className="badge rounded-pill bg-light text-dark">business</span>&nbsp;<span className="badge rounded-pill bg-light text-dark">marketing</span><span className="badge rounded-pill bg-light text-dark">android</span>&nbsp;<span className="badge rounded-pill bg-light text-dark">business</span>&nbsp;<span className="badge rounded-pill bg-light text-dark">marketing</span><span className="badge rounded-pill bg-light text-dark">android</span>&nbsp;<span className="badge rounded-pill bg-light text-dark">business</span>&nbsp;<span className="badge rounded-pill bg-light text-dark">marketing</span></span> */}
                                            <span className="skill-tags"><span className="badge rounded-pill bg-light text-dark">{maDetails.Profile !== null ? maDetails.Profile.specification : ''}</span></span>
                                        </div>
                                        {/* / item */}
                                        {/* item */}
                                        <div className="partner-specializations__wrapper">
                                            <div className="feature-icon-placeholder" style={{ backgroundImage: `url(/${Constants.SUB_DIRECTORY}/images/icons/expertise.webp)` }} />
                                            <h5 className="spec-title">Expertise</h5>
                                            {/* item */}
                                            <div className="expertise-area__wrapper">
                                                <h5 className="expe-title">{maDetails.Profile !== null ? maDetails.Profile.expertise : ''}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="quick-contact__wrapper">
                                        <div className="quick-contact-summery">
                                            <div className="quick-contact__wrapper">
                                                <h5 className="spec-title">Contact Details</h5>
                                                <a href="mailto:" className="quick-contact-action"><i className="bi bi-envelope-fill" />&nbsp;{maDetails.email}</a>
                                                <a href="tel:+" className="quick-contact-action"><i className="bi bi-telephone-fill" />&nbsp;{maDetails.contact}</a>
                                                {/* <a href="http://" target="_blank" rel="noopener noreferrer" className="quick-contact-action"><i className="bi bi-globe" />&nbsp;https://linkedin/user profile</a> */}
                                                <button
                                                    onClick={() => onOpenContact(maDetails.email)}
                                                    className="btn btn-primary btn-lg" alt="primary contact method">Contact
                                                    partner</button>
                                            </div>
                                            {/* <h5 className="spec-title">Social</h5>
                                            <div className="quick-social-summery">
                                                <a href="http://" target="_blank" rel="noopener noreferrer" className="social-btn facebook" id="socialFacebookBtn"><i className="bi bi-facebook" /></a>
                                                <a href="http://" target="_blank" rel="noopener noreferrer" className="social-btn twitter" id="socialFacebookBtn"><i className="bi bi-twitter" /></a>
                                                <a href="http://" target="_blank" rel="noopener noreferrer" className="social-btn whatsapp" id="socialFacebookBtn"><i className="bi bi-whatsapp" /></a>
                                                <a href="http://" target="_blank" rel="noopener noreferrer" className="social-btn messenger" id="socialFacebookBtn"><i className="bi bi-messenger" /></a>
                                            </div> */}
                                            <div className="other-details__wrapper">
                                                <h5 className="other-details-title">Years of experiance</h5>
                                                <p className="other-details-content">{maDetails.Profile !== null ? maDetails.Profile.yearsOfExperience : ''} years</p>
                                            </div>
                                            <div className="other-details__wrapper">
                                                <h5 className="other-details-title">Address</h5>
                                                <p className="other-details-content"><i className="bi bi-geo-alt-fill" />&nbsp;{maDetails.Profile !== null ? maDetails.Profile.addressNumber + ', ' + maDetails.Profile.addressStreet + ', ' + maDetails.Profile.addressCity + ', ' + maDetails.Profile.addressDistrict : ''}</p>
                                            </div>
                                            <div className="other-details__wrapper">
                                                <h5 className="other-details-title">Joined date</h5>
                                                <p className="other-details-content">{Moment(maDetails.createdAt).format('MMMM Do YYYY')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></section>
                {/* / What is */}
                <section className="more-items-scroller common-section left-content" id htmlFor>
                    <div className="container-fluid">
                        <div className="container">
                            <div className="left-details__wrapper">
                                <p className="sec-sub-title">More partners</p>
                                <h2 className="sec-main-title">Get to know more partners</h2>
                                <p className="sec-dec-wrapper">We have hand picked partners for your reqirement </p>
                            </div>
                            <ScrollContainer className="items-scroller__wrapper dragscroll">
                                {Array.isArray(maList) && maList.map((value, index) => {
                                    return (
                                        <Link
                                            key={index}
                                            to={`/partners/${value.userName}/${value.uuid}`}
                                            className="partner-widget">
                                            <div className="verified-badge">
                                                <i className="bi bi-patch-check-fill" />
                                                {/* <span>verified</span> */}
                                            </div>
                                            <div className="partner-avatar" style={{ backgroundImage: `url(${((value.Profile !== null && value.Profile.profilePic !== null) ? value.Profile.profilePic : `url(${avatar})`)})` }}>
                                            </div>
                                            <div className="partner-details__wrapper">
                                                <p className="p-name">{value.firstName} {value.lastName}</p>
                                                {/* {maDetails.Profile !== null ? maDetails.Profile.specification : ''} */}
                                                {/* <EllipsisText text={(typeof this.props.Desc !== 'undefined' && this.props.Desc !== null) ? this.props.Desc : ''} length={"150"} /> */}
                                                {(value.Profile !== null && value.Profile.specification !== null) ? <p className="skill-tags"><span className="badge rounded-pill bg-light text-dark"><EllipsisText text={value.Profile.specification} length={"40"} /></span></p> : <p></p>}
                                            </div>
                                        </Link>
                                    )
                                })}
                            </ScrollContainer>
                        </div>
                    </div>
                </section>
            </>
        );
    } else {
        return (
            <section className="main-hero inner-hero">
                <div className="container-fluid">
                    <div className="container">
                        <div className="hero__wrapper">
                            <div className="hero-content">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 text">
                                        <button
                                            className="btn back-btn"
                                            onClick={() => onBack()}
                                        > <span /> Go back
                                        </button>
                                        <Skeleton active />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SinglePartner;