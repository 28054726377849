import React from 'react'
import { InboxOutlined } from '@ant-design/icons';
// import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';

type Props = {

}

const { Dragger } = Upload;

const FileUpload = (props: any) => {

    const {
        onChangeFile,
        fileList,
        onDropFile,
        beforeUploadFile,
        maxCount,
        multiple,
        validator,
        rules,
        name,
        label,
        required,
        classes,
        fileTypes,
        disabled,
        uploadSizeError,
        maxSize
    } = props;

    return (
        <div className={"form-element__wrapper mb-3 " + classes}>
            <label htmlFor="" className="form-label">
                {label}
                {required && <span aria-label="required"></span>}
            </label>
            <div className="form-element__wrapper validated mb-3">
                <Dragger
                    accept={fileTypes}
                    onChange={(file) => onChangeFile(file)}
                    beforeUpload={(file) => beforeUploadFile(file)}
                    fileList={fileList}
                    multiple={multiple}
                    maxCount={maxCount}
                    onRemove={onDropFile}
                    disabled={disabled}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for {fileTypes} file types only.
                    </p>
                    <p className="ant-upload-hint">
                        Max file size allowed {maxSize} MB
                    </p>
                </Dragger>
                {validator && <div className="validation invalid-feedback">{validator.message(name, fileList, rules)}</div>}
                {uploadSizeError && uploadSizeError != "" && (<div className="validation invalid-feedback">{uploadSizeError}</div> )}
            </div>
        </div>
    )
}

export { FileUpload }