import { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Footer } from '../components';
import { useNavigate } from "react-router-dom";


type AppRoutesProps = {

}

const RoutesLayout: FC<AppRoutesProps> = ({ }) => {
    const routeName = window.location.pathname.split('/')[1];
    let isSettingsPage = routeName === "settings";
    let navigate = useNavigate();
    const [screenWidth, setWidth] = useState(window.innerWidth);
    const [isMenuOpenOpen, setIsMenuOpen] = useState(false);
    return (
        <main className='max-width-set'>
            <Header
                isMenuOpenOpen={isMenuOpenOpen}
                setIsMenuOpen={setIsMenuOpen}
            />
            <section className={"content__wrapper with-nav-side-bar"} onClick={() => {
                // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@");
                setIsMenuOpen(false);
            }}>
                <Outlet />
                <Footer />
            </section>
        </main>
    )
}

export default RoutesLayout;